import React, { useEffect, useState } from "react";
import { useDraggable } from "@dnd-kit/core";
import { useGameplayStore } from "../store/useGameplayStore";

interface DraggableProps {
  id: string;
  children: React.ReactNode;
  className?: string;
}

export function Draggable(props: DraggableProps) {
  const { lockDie, dragIn } = useGameplayStore();
  const [isDragging, setIsDragging] = useState(false);

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
  });

  useEffect(() => {
    setIsDragging(!!transform);
  }, [transform]);

  // Check if the className contains "correct"
  const isCorrect = props.className?.includes("correct");

  // If the className contains "correct", render children without draggable functionality
  if (isCorrect) {
    return <>{props.children}</>;
  }

  // Base style for both dragged and non-dragged states
  const baseStyle = {
    padding: 0,
    width: "fit-content",
    border: "none",
    borderRadius: "1vh",
  };

  // Style for the dragged item
  const draggedStyle = {
    ...baseStyle,
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    zIndex: 1000,
    position: "relative", // Changed from "absolute" to "relative"
  };

  // Style for the ghost item
  const ghostStyle: React.CSSProperties = {
    ...baseStyle,
    opacity: 0.5,
    position: "absolute",
    pointerEvents: "none" as const,
  };

  return (
    <div style={{ position: "relative" }}>
      {isDragging && <div style={ghostStyle}>{props.children}</div>}
      <button
        ref={setNodeRef}
        {...listeners}
        {...attributes}
        style={{
          ...(isDragging ? draggedStyle : baseStyle),
          // opacity: isDragging ? 0 : 1,
        }}
        className={props.className}
      >
        {props.children}
      </button>
    </div>
  );
}
