import React from "react";
import { Route, Routes as Switch, useLocation } from "react-router-dom";

import { MainGameScreen } from "./screens/MainGameScreen";
import { TestScreen } from "./screens/TestScreen";
import { WhackScreen } from "./screens/WhackScreen";

export const Routes = (): React.ReactElement => {
  return (
    <>
      <Switch>
        {/* FIXME: Replace this with ChatContainer */}
        <Route path="/" element={<MainGameScreen />} />
        <Route path="/test" element={<TestScreen />} />
        <Route path="/whack" element={<WhackScreen />} />
      </Switch>
    </>
  );
};
