import React, { memo, useState, useEffect } from "react";
import { Dice } from "../components/Dice";
import { CombinedCard } from "../components/CombinedCard";
import { useGameplayStore } from "../store/useGameplayStore";
import "./TestScreen.scss";

export const TestScreen = memo(() => {
  const {
    reroll,
    playerDies: dies,
    restartGame,
    go,
    flip,
    submitDie,
    rerollCount,
    enemyAttack,
    half,
    playerCategory,
    guessedDice,
    clear,
    guessRound,
  } = useGameplayStore();

  const [enemyHealth, setEnemyHealth] = useState(25);

  useEffect(() => {
    if (enemyHealth === 0) alert("You won!");
  }, [enemyHealth]);

  const renderPlayerDice = () => (
    <div className="player-die-container">
      {dies.map((die, index) => (
        <Dice key={index} {...die} isPlayerDice={true} />
      ))}
    </div>
  );

  const renderGuessedDice = () => (
    <div className="empty-die-container">
      {guessedDice.map((die, index) => (
        <div key={index}>
          <Dice {...die} isPlayerDice={false} />
          <div className="flip" onClick={() => flip(index)}>
            ⇄
          </div>
        </div>
      ))}
    </div>
  );

  const renderGameControls = () => (
    <div className="extra-game-controls">
      <button className="clear" onClick={clear}>
        Clear
      </button>
      {half % 2 === 1 && (
        <button className="lock" onClick={submitDie}>
          Start
        </button>
      )}
      {half % 2 === 0 && (
        <button className="go" onClick={go}>
          Guess
        </button>
      )}
    </div>
  );

  const renderEnemyAttack = () => (
    <div className="combined-attack-enemy">
      <CombinedCard {...enemyAttack} />
    </div>
  );

  const renderEnemyAttackModal = () =>
    typeof enemyAttack.effect === "object" && (
      <div className="modal combined-attack-enemy">
        <div className="modal-content">
          <CombinedCard {...enemyAttack} />
          <br />
          <button
            className="modal-close"
            onClick={() => {
              useGameplayStore.setState({
                enemyAttack: { ...enemyAttack, effect: "" },
              });
            }}
          >
            Close
          </button>
        </div>
      </div>
    );

  return (
    <div id="main-game-screen">
      <main>
        {renderPlayerDice()}
        {renderGuessedDice()}
        {renderGameControls()}
        <div>
          <span>
            <strong>Rounds</strong>
          </span>
          <br />
          <span className="score">{guessRound + 1}</span>
        </div>
        <div className="game-controls">
          <button className="restart" onClick={restartGame}>
            New Game
          </button>
        </div>
        {renderEnemyAttack()}
        {renderEnemyAttackModal()}
      </main>
    </div>
  );
});

TestScreen.displayName = "TestScreen";
