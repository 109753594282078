import React from "react";
import { View, StyleSheet } from "react-native";
// import HomePage from "../myComponents/HomePage";
import { NumberGridGame } from "../myComponents/NumberGridGame";

interface WhackScreenProps {
  // Add any props you might need
}

export const WhackScreen: React.FC<WhackScreenProps> = () => {
  return (
    <View style={styles.container}>
      {/* {<HomePage />} */}
      {<NumberGridGame />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F5FCFF",
  },
});

WhackScreen.displayName = "WhackScreen";

// ... existing NumberGridGame component code ...
