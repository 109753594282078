import React from "react";
import { Theme } from "@radix-ui/themes";
// import "./core/reset.scss";
import "./App.scss";
import "./app/globals.css";

import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./Routes";

function App() {
  return (
    <div className="App">
      <Router>
        <Theme appearance="dark">
          <div id={"app-container"}>
            <Routes />
          </div>
        </Theme>
      </Router>
    </div>
  );
}

export default App;
