export const Items = [
  { word: "Africa", emoji: "🌍" },
  { word: "Agent", emoji: "🕵️‍♂️" },
  { word: "Air", emoji: "💨" },
  { word: "Alien", emoji: "👽" },
  { word: "Alps", emoji: "🏔️" },
  { word: "Amazon", emoji: "🌲" },
  { word: "Ambulance", emoji: "🚑" },
  { word: "America", emoji: "🇺🇸" },
  { word: "Angel", emoji: "👼" },
  { word: "Antarctica", emoji: "❄️" },
  { word: "Apple", emoji: "🍎" },
  { word: "Arm", emoji: "💪" },
  { word: "Atlantis", emoji: "🏛️" },
  { word: "Australia", emoji: "🇦🇺" },
  { word: "Aztec", emoji: "🏺" },
  { word: "Back", emoji: "🔙" },
  { word: "Ball", emoji: "⚽" },
  { word: "Band", emoji: "🎸" },
  { word: "Bank", emoji: "🏦" },
  { word: "Bar", emoji: "🍸" },
  { word: "Bark", emoji: "🐕" },
  { word: "Bat", emoji: "🦇" },
  { word: "Battery", emoji: "🔋" },
  { word: "Beach", emoji: "🏖️" },
  { word: "Bear", emoji: "🐻" },
  { word: "Beat", emoji: "🥁" },
  { word: "Bed", emoji: "🛏️" },
  { word: "Beijing", emoji: "🇨🇳" },
  { word: "Bell", emoji: "🔔" },
  { word: "Belt", emoji: "👖" },
  { word: "Berlin", emoji: "🇩🇪" },
  { word: "Bermuda", emoji: "🏝️" },
  { word: "Berry", emoji: "🍓" },
  { word: "Bill", emoji: "💵" },
  { word: "Block", emoji: "🧱" },
  { word: "Board", emoji: "🛹" },
  { word: "Bolt", emoji: "⚡" },
  { word: "Bomb", emoji: "💣" },
  { word: "Bond", emoji: "🔗" },
  { word: "Boom", emoji: "💥" },
  { word: "Boot", emoji: "👢" },
  { word: "Bottle", emoji: "🍾" },
  { word: "Bow", emoji: "🏹" },
  { word: "Box", emoji: "📦" },
  { word: "Bridge", emoji: "🌉" },
  { word: "Brush", emoji: "🖌️" },
  { word: "Buck", emoji: "🦌" },
  { word: "Buffalo", emoji: "🐃" },
  { word: "Bug", emoji: "🐛" },
  { word: "Bugle", emoji: "📯" },
  { word: "Button", emoji: "🔘" },
  { word: "Calf", emoji: "🐄" },
  { word: "Canada", emoji: "🇨🇦" },
  { word: "Cap", emoji: "🧢" },
  { word: "Capital", emoji: "🏛️" },
  { word: "Car", emoji: "🚗" },
  { word: "Card", emoji: "🃏" },
  { word: "Carrot", emoji: "🥕" },
  { word: "Casino", emoji: "🎰" },
  { word: "Cast", emoji: "🎭" },
  { word: "Cat", emoji: "🐱" },
  { word: "Cell", emoji: "📱" },
  { word: "Centaur", emoji: "🐴" },
  { word: "Center", emoji: "🏙️" },
  { word: "Chair", emoji: "🪑" },
  { word: "Change", emoji: "💰" },
  { word: "Charge", emoji: "🔋" },
  { word: "Check", emoji: "✅" },
  { word: "Chest", emoji: "🧰" },
  { word: "Chick", emoji: "🐤" },
  { word: "China", emoji: "🇨🇳" },
  { word: "Chocolate", emoji: "🍫" },
  { word: "Church", emoji: "⛪" },
  { word: "Circle", emoji: "⭕" },
  { word: "Cliff", emoji: "🪨" },
  { word: "Cloak", emoji: "🧥" },
  { word: "Club", emoji: "🎵" },
  { word: "Code", emoji: "💻" },
  { word: "Cold", emoji: "🥶" },
  { word: "Comic", emoji: "📚" },
  { word: "Compound", emoji: "🏘️" },
  { word: "Concert", emoji: "🎤" },
  { word: "Conductor", emoji: "👨‍🎤" },
  { word: "Contract", emoji: "📃" },
  { word: "Cook", emoji: "👨‍🍳" },
  { word: "Copper", emoji: "🪙" },
  { word: "Cotton", emoji: "👚" },
  { word: "Court", emoji: "⚖️" },
  { word: "Cover", emoji: "📖" },
  { word: "Crane", emoji: "🏗️" },
  { word: "Crash", emoji: "💥" },
  { word: "Cricket", emoji: "🏏" },
  { word: "Cross", emoji: "✝️" },
  { word: "Crown", emoji: "👑" },
  { word: "Cycle", emoji: "🚴" },
  { word: "Czech", emoji: "🇨🇿" },
  { word: "Dance", emoji: "💃" },
  { word: "Date", emoji: "📅" },
  { word: "Day", emoji: "🌞" },
  { word: "Death", emoji: "💀" },
  { word: "Deck", emoji: "🃏" },
  { word: "Degree", emoji: "🎓" },
  { word: "Diamond", emoji: "💎" },
  { word: "Dice", emoji: "🎲" },
  { word: "Dinosaur", emoji: "🦕" },
  { word: "Disease", emoji: "🦠" },
  { word: "Doctor", emoji: "👩‍⚕️" },
  { word: "Dog", emoji: "🐕" },
  { word: "Draft", emoji: "✍️" },
  { word: "Dragon", emoji: "🐉" },
  { word: "Dress", emoji: "👗" },
  { word: "Drill", emoji: "🛠️" },
  { word: "Drop", emoji: "💧" },
  { word: "Duck", emoji: "🦆" },
  { word: "Dwarf", emoji: "👨‍🌾" },
  { word: "Eagle", emoji: "🦅" },
  { word: "Egypt", emoji: "🇪🇬" },
  { word: "Embassy", emoji: "🏛️" },
  { word: "Engine", emoji: "🚂" },
  { word: "England", emoji: "🏴" },
  { word: "Europe", emoji: "🌍" },
  { word: "Eye", emoji: "👁️" },
  { word: "Face", emoji: "😊" },
  { word: "Fair", emoji: "🎡" },
  { word: "Fall", emoji: "🍁" },
  { word: "Fan", emoji: "🎐" },
  { word: "Fence", emoji: "🏚️" },
  { word: "Field", emoji: "🌾" },
  { word: "Fighter", emoji: "🥋" },
  { word: "Figure", emoji: "👤" },
  { word: "File", emoji: "📁" },
  { word: "Film", emoji: "🎬" },
  { word: "Fire", emoji: "🔥" },
  { word: "Fish", emoji: "🐟" },
  { word: "Flute", emoji: "🎶" },
  { word: "Fly", emoji: "✈️" },
  { word: "Foot", emoji: "🦶" },
  { word: "Force", emoji: "💪" },
  { word: "Forest", emoji: "🌲" },
  { word: "Fork", emoji: "🍴" },
  { word: "France", emoji: "🇫🇷" },
  { word: "Game", emoji: "🎮" },
  { word: "Gas", emoji: "⛽" },
  { word: "Genius", emoji: "🧠" },
  { word: "Germany", emoji: "🇩🇪" },
  { word: "Ghost", emoji: "👻" },
  { word: "Giant", emoji: "🦣" },
  { word: "Glass", emoji: "🍷" },
  { word: "Glove", emoji: "🧤" },
  { word: "Gold", emoji: "🥇" },
  { word: "Grace", emoji: "💃" },
  { word: "Grass", emoji: "🌿" },
  { word: "Greece", emoji: "🇬🇷" },
  { word: "Green", emoji: "🟢" },
  { word: "Ground", emoji: "🌍" },
  { word: "Ham", emoji: "🍖" },
  { word: "Hand", emoji: "✋" },
  { word: "Hawk", emoji: "🦅" },
  { word: "Head", emoji: "🧑" },
  { word: "Heart", emoji: "❤️" },
  { word: "Helicopter", emoji: "🚁" },
  { word: "Himalayas", emoji: "🏔️" },
  { word: "Hole", emoji: "🕳️" },
  { word: "Hollywood", emoji: "🎥" },
  { word: "Honey", emoji: "🍯" },
  { word: "Hood", emoji: "🧢" },
  { word: "Hook", emoji: "🪝" },
  { word: "Horn", emoji: "📯" },
  { word: "Horse", emoji: "🐎" },
  { word: "Horseshoe", emoji: "🐴" },
  { word: "Hospital", emoji: "🏥" },
  { word: "Hotel", emoji: "🏨" },
  { word: "Ice", emoji: "❄️" },
  { word: "Ice Cream", emoji: "🍦" },
  { word: "India", emoji: "🇮🇳" },
  { word: "Iron", emoji: "🔩" },
  { word: "Ivory", emoji: "🐘" },
  { word: "Jack", emoji: "🔌" },
  { word: "Jam", emoji: "🍓" },
  { word: "Jet", emoji: "✈️" },
  { word: "Jupiter", emoji: "🪐" },
  { word: "Kangaroo", emoji: "🦘" },
  { word: "Ketchup", emoji: "🍅" },
  { word: "Key", emoji: "🔑" },
  { word: "Kid", emoji: "👦" },
  { word: "King", emoji: "👑" },
  { word: "Knife", emoji: "🔪" },
  { word: "Knight", emoji: "🤺" },
  { word: "Lab", emoji: "🔬" },
  { word: "Lap", emoji: "🦵" },
  { word: "Laser", emoji: "🔦" },
  { word: "Lawyer", emoji: "⚖️" },
  { word: "Lead", emoji: "🔗" },
  { word: "Lemon", emoji: "🍋" },
  { word: "Leprechaun", emoji: "🍀" },
  { word: "Life", emoji: "💓" },
  { word: "Light", emoji: "💡" },
  { word: "Limousine", emoji: "🚓" },
  { word: "Line", emoji: "📏" },
  { word: "Link", emoji: "🔗" },
  { word: "Lion", emoji: "🦁" },
  { word: "Litter", emoji: "🗑️" },
  { word: "Loch Ness", emoji: "🏞️" },
  { word: "Lock", emoji: "🔒" },
  { word: "Log", emoji: "🪵" },
  { word: "London", emoji: "🇬🇧" },
  { word: "Luck", emoji: "🍀" },
  { word: "Mail", emoji: "📫" },
  { word: "Mammoth", emoji: "🦣" },
  { word: "Maple", emoji: "🍁" },
  { word: "Marble", emoji: "⚪" },
  { word: "March", emoji: "📅" },
  { word: "Mass", emoji: "⚖️" },
  { word: "Match", emoji: "🎾" },
  { word: "Mercury", emoji: "☿️" },
  { word: "Mexico", emoji: "🇲🇽" },
  { word: "Microscope", emoji: "🔬" },
  { word: "Millionaire", emoji: "💰" },
  { word: "Mine", emoji: "⛏️" },
  { word: "Mint", emoji: "🌿" },
  { word: "Missile", emoji: "🚀" },
  { word: "Model", emoji: "🖼️" },
  { word: "Mole", emoji: "🦦" },
  { word: "Moon", emoji: "🌙" },
  { word: "Moscow", emoji: "🇷🇺" },
  { word: "Mount", emoji: "🗻" },
  { word: "Mouse", emoji: "🐭" },
  { word: "Mouth", emoji: "👄" },
  { word: "Mug", emoji: "☕" },
  { word: "Nail", emoji: "💅" },
  { word: "Needle", emoji: "💉" },
  { word: "Net", emoji: "🎣" },
  { word: "New York", emoji: "🗽" },
  { word: "Night", emoji: "🌌" },
  { word: "Ninja", emoji: "🥷" },
  { word: "Note", emoji: "📝" },
  { word: "Novel", emoji: "📚" },
  { word: "Nurse", emoji: "👩‍⚕️" },
  { word: "Nut", emoji: "🥜" },
  { word: "Octopus", emoji: "🐙" },
  { word: "Oil", emoji: "🛢️" },
  { word: "Olive", emoji: "🫒" },
  { word: "Olympus", emoji: "🏛️" },
  { word: "Opera", emoji: "🎭" },
  { word: "Orange", emoji: "🍊" },
  { word: "Organ", emoji: "🎹" },
  { word: "Palm", emoji: "🌴" },
  { word: "Pan", emoji: "🍳" },
  { word: "Pants", emoji: "👖" },
  { word: "Paper", emoji: "📄" },
  { word: "Parachute", emoji: "🪂" },
  { word: "Park", emoji: "🏞️" },
  { word: "Part", emoji: "🧩" },
  { word: "Pass", emoji: "🎫" },
  { word: "Paste", emoji: "🧴" },
  { word: "Penguin", emoji: "🐧" },
  { word: "Phoenix", emoji: "🦅" },
  { word: "Piano", emoji: "🎹" },
  { word: "Pie", emoji: "🥧" },
  { word: "Pilot", emoji: "👨‍✈️" },
  { word: "Pin", emoji: "📍" },
  { word: "Pipe", emoji: "🚬" },
  { word: "Pirate", emoji: "🏴‍☠️" },
  { word: "Pistol", emoji: "🔫" },
  { word: "Pit", emoji: "🕳️" },
  { word: "Pitch", emoji: "⚾" },
  { word: "Plane", emoji: "✈️" },
  { word: "Plastic", emoji: "♻️" },
  { word: "Plate", emoji: "🍽️" },
  { word: "Platypus", emoji: "🦆" },
  { word: "Play", emoji: "🎭" },
  { word: "Plot", emoji: "📈" },
  { word: "Point", emoji: "📍" },
  { word: "Poison", emoji: "☠️" },
  { word: "Pole", emoji: "🇵🇱" },
  { word: "Police", emoji: "👮" },
  { word: "Pool", emoji: "🏊" },
  { word: "Port", emoji: "🛳️" },
  { word: "Post", emoji: "📬" },
  { word: "Pound", emoji: "💷" },
  { word: "Press", emoji: "🗞️" },
  { word: "Princess", emoji: "👸" },
  { word: "Pumpkin", emoji: "🎃" },
  { word: "Pupil", emoji: "👁️" },
  { word: "Pyramid", emoji: "🔺" },
  { word: "Queen", emoji: "👸" },
  { word: "Rabbit", emoji: "🐇" },
  { word: "Racket", emoji: "🎾" },
  { word: "Ray", emoji: "🔆" },
  { word: "Revolution", emoji: "⚙️" },
  { word: "Ring", emoji: "💍" },
  { word: "Robin", emoji: "🐦" },
  { word: "Robot", emoji: "🤖" },
  { word: "Rock", emoji: "🪨" },
  { word: "Rome", emoji: "🇮🇹" },
  { word: "Root", emoji: "🌱" },
  { word: "Rose", emoji: "🌹" },
  { word: "Roulette", emoji: "🎰" },
  { word: "Round", emoji: "🔄" },
  { word: "Row", emoji: "🛶" },
  { word: "Ruler", emoji: "📏" },
  { word: "Satellite", emoji: "🛰️" },
  { word: "Saturn", emoji: "🪐" },
  { word: "Scale", emoji: "⚖️" },
  { word: "School", emoji: "🏫" },
  { word: "Scientist", emoji: "👩‍🔬" },
  { word: "Scorpion", emoji: "🦂" },
  { word: "Screen", emoji: "📺" },
  { word: "Scuba Diver", emoji: "🤿" },
  { word: "Seal", emoji: "🦭" },
  { word: "Server", emoji: "💾" },
  { word: "Shadow", emoji: "🌑" },
  { word: "Shakespeare", emoji: "📝" },
  { word: "Shark", emoji: "🦈" },
  { word: "Ship", emoji: "🚢" },
  { word: "Shoe", emoji: "👟" },
  { word: "Shop", emoji: "🏪" },
  { word: "Shot", emoji: "🥃" },
  { word: "Sink", emoji: "🚰" },
  { word: "Skyscraper", emoji: "🏙️" },
  { word: "Slip", emoji: "🛷" },
  { word: "Slug", emoji: "🐌" },
  { word: "Smuggler", emoji: "🕵️" },
  { word: "Snow", emoji: "❄️" },
  { word: "Snowman", emoji: "⛄" },
  { word: "Sock", emoji: "🧦" },
  { word: "Soldier", emoji: "🪖" },
  { word: "Soul", emoji: "👻" },
  { word: "Sound", emoji: "🔊" },
  { word: "Space", emoji: "🌌" },
  { word: "Spell", emoji: "🔮" },
  { word: "Spider", emoji: "🕷️" },
  { word: "Spike", emoji: "🌵" },
  { word: "Spine", emoji: "💀" },
  { word: "Spot", emoji: "🔴" },
  { word: "Spring", emoji: "🌸" },
  { word: "Spy", emoji: "🕶️" },
  { word: "Square", emoji: "🔲" },
  { word: "Stadium", emoji: "🏟️" },
  { word: "Staff", emoji: "💼" },
  { word: "Star", emoji: "⭐" },
  { word: "State", emoji: "🏛️" },
  { word: "Stick", emoji: "🌿" },
  { word: "Stock", emoji: "📈" },
  { word: "Straw", emoji: "🍹" },
  { word: "Stream", emoji: "🚿" },
  { word: "Strike", emoji: "⚡" },
  { word: "String", emoji: "🧵" },
  { word: "Sub", emoji: "🚇" },
  { word: "Suit", emoji: "🕴️" },
  { word: "Superhero", emoji: "🦸" },
  { word: "Swing", emoji: "🏌️" },
  { word: "Switch", emoji: "🔄" },
  { word: "Table", emoji: "🍽️" },
  { word: "Tablet", emoji: "💊" },
  { word: "Tag", emoji: "🏷️" },
  { word: "Tail", emoji: "🦊" },
  { word: "Tap", emoji: "🚰" },
  { word: "Teacher", emoji: "👨‍🏫" },
  { word: "Telescope", emoji: "🔭" },
  { word: "Temple", emoji: "🏯" },
  { word: "Theater", emoji: "🎭" },
  { word: "Thief", emoji: "🕵️" },
  { word: "Thumb", emoji: "👍" },
  { word: "Tick", emoji: "✅" },
  { word: "Tie", emoji: "👔" },
  { word: "Time", emoji: "⏰" },
  { word: "Tokyo", emoji: "🗼" },
  { word: "Tooth", emoji: "🦷" },
  { word: "Torch", emoji: "🔥" },
  { word: "Tower", emoji: "🗼" },
  { word: "Track", emoji: "🏃" },
  { word: "Train", emoji: "🚆" },
  { word: "Triangle", emoji: "🔺" },
  { word: "Trip", emoji: "🧳" },
  { word: "Trunk", emoji: "🐘" },
  { word: "Tube", emoji: "🛁" },
  { word: "Turkey", emoji: "🦃" },
  { word: "Undertaker", emoji: "⚰️" },
  { word: "Unicorn", emoji: "🦄" },
  { word: "Vacuum", emoji: "🧹" },
  { word: "Van", emoji: "🚐" },
  { word: "Vet", emoji: "👩‍⚕️" },
  { word: "Wake", emoji: "🌅" },
  { word: "Wall", emoji: "🧱" },
  { word: "War", emoji: "⚔️" },
  { word: "Washer", emoji: "🧺" },
  { word: "Washington", emoji: "🗽" },
  { word: "Watch", emoji: "⌚" },
  { word: "Water", emoji: "💧" },
  { word: "Wave", emoji: "🌊" },
  { word: "Web", emoji: "🕸️" },
  { word: "Well", emoji: "🔴" },
  { word: "Whale", emoji: "🐋" },
  { word: "Whip", emoji: "🥚" },
  { word: "Wind", emoji: "💨" },
  { word: "Witch", emoji: "🧙" },
  { word: "Worm", emoji: "🪱" },
  { word: "Yard", emoji: "🌿" },
  { word: "Accordion", emoji: "🪗" },
  { word: "Acrobat", emoji: "🤸" },
  { word: "Avalanche", emoji: "🏔️" },
  { word: "Bonsai", emoji: "🎍" },
  { word: "Boomerang", emoji: "🪃" },
  { word: "Cactus", emoji: "🌵" },
  { word: "Catapult", emoji: "🏹" },
  { word: "Chameleon", emoji: "🦎" },
  { word: "Chimney", emoji: "🏠" },
  { word: "Compass", emoji: "🧭" },
  { word: "Confetti", emoji: "🎉" },
  { word: "Coral", emoji: "🪸" },
  { word: "Croissant", emoji: "🥐" },
  { word: "Dandelion", emoji: "🌼" },
  { word: "Dodo", emoji: "🦤" },
  { word: "Domino", emoji: "🁡" },
  { word: "Dumbbell", emoji: "🏋️" },
  { word: "Eclipse", emoji: "🌘" },
  { word: "Flamingo", emoji: "🦩" },
  { word: "Gargoyle", emoji: "🗿" },
  { word: "Geyser", emoji: "♨️" },
  { word: "Glacier", emoji: "🧊" },
  { word: "Hammock", emoji: "🛌" },
  { word: "Harp", emoji: "🎭" },
  { word: "Igloo", emoji: "🧊" },
  { word: "Jigsaw", emoji: "🧩" },
  { word: "Kazoo", emoji: "🎵" },
  { word: "Labyrinth", emoji: "🌀" },
  { word: "Lighthouse", emoji: "🏯" },
  { word: "Mirage", emoji: "🏜️" },
  { word: "Monsoon", emoji: "🌧️" },
  { word: "Mosaic", emoji: "🔲" },
  { word: "Origami", emoji: "🎎" },
  { word: "Pacifier", emoji: "🍼" },
  { word: "Pinwheel", emoji: "🎡" },
  { word: "Prism", emoji: "🌈" },
  { word: "Quicksand", emoji: "⏳" },
  { word: "Radar", emoji: "📡" },
  { word: "Rickshaw", emoji: "🛺" },
  { word: "Sarcophagus", emoji: "⚰️" },
  { word: "Seesaw", emoji: "🎢" },
  { word: "Sombrero", emoji: "👒" },
  { word: "Sphinx", emoji: "🐈" },
  { word: "Stampede", emoji: "🐎" },
  { word: "Sundial", emoji: "🕰️" },
  { word: "Tarot", emoji: "🔮" },
  { word: "Teepee", emoji: "⛺" },
  { word: "Tornado", emoji: "🌪️" },
  { word: "Trampoline", emoji: "🏋️" },
  { word: "Tsunami", emoji: "🌊" },
  { word: "Ukulele", emoji: "🎸" },
  { word: "Volcano", emoji: "🌋" },
  { word: "Vortex", emoji: "🌀" },
  { word: "Xylophone", emoji: "🎵" },
  { word: "Yeti", emoji: "❄️" },
  { word: "Zen", emoji: "☯️" },
  { word: "Zodiac", emoji: "♈" },
  { word: "Backpack", emoji: "🎒" },
  { word: "Blender", emoji: "🧉" },
  { word: "Canoe", emoji: "🛶" },
  { word: "Dartboard", emoji: "🎯" },
  { word: "Escalator", emoji: "🚶" },
  { word: "Ferris wheel", emoji: "🎡" },
  { word: "Gondola", emoji: "🚠" },
  { word: "Hammock", emoji: "🛌" },
  { word: "Ironing board", emoji: "🛋️" },
  { word: "Jacuzzi", emoji: "🛀" },
  { word: "Kayak", emoji: "🛶" },
  { word: "Lawnmower", emoji: "🌿" },
  { word: "Megaphone", emoji: "📢" },
  { word: "Necklace", emoji: "📿" },
  { word: "Omelette", emoji: "🍳" },
  { word: "Pinata", emoji: "🪅" },
  { word: "Quilt", emoji: "🛏️" },
  { word: "Rollerskate", emoji: "🛼" },
  { word: "Scarecrow", emoji: "🎃" },
  { word: "Toaster", emoji: "🍞" },
  { word: "Umbrella", emoji: "☂️" },
  { word: "Vending machine", emoji: "🏧" },
  { word: "Waffle", emoji: "🧇" },
  { word: "Xylophone", emoji: "🎵" },
  { word: "Yo-yo", emoji: "🪀" },
  { word: "Zipper", emoji: "🧥" },
  { word: "Birdhouse", emoji: "🐦" },
  { word: "Corkscrew", emoji: "🍾" },
  { word: "Frisbee", emoji: "🥏" },
  { word: "Gnome", emoji: "🍄" },
];
