import { create } from "zustand";
import { persist } from "zustand/middleware";
import { v4 as uuidv4 } from "uuid";
import { Map as ImmMap } from "immutable";
import { DieState } from "./RoloTypes";
import { CardInfo } from "./CombinedCard";
import { MoveResult } from "./MoveResult";
import { Player } from "../components/Player";
import { useState } from "react";
import { Items as words } from "./wordLists/Codenames";
import Anthropic from "@anthropic-ai/sdk";
// import { words } from './elements';
// import { words } from "./wordLists/complexElements 2";
const usedIndices = new Set<number>();
const currentlyAssignedWords = new Set<string>();
const TOGGLES = {
  numberOfRerolls: 2,
  numberOfDice: 25,
  valueOfDice: 5,
  numberOfGuesses: 5,
  // guessRou/nd: 5,
};

const CLAUDE_PROXY_URL = "https://claude-worker.mmmpage.workers.dev/";
const anthropic = new Anthropic({
  apiKey:
    "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", // defaults to process.env["ANTHROPIC_API_KEY"]
  dangerouslyAllowBrowser: true,
});

interface GameplayState {
  offPositionGuesses: { [dieIndex: number]: number[] };
  threshold: number;
  playerDies: Array<DieState>;
  guessedDice: Array<DieState>;
  playerAttack: CardInfo;
  enemyAttack: CardInfo;
  moveresult: MoveResult;
  previousGuessStates: { [dieIndex: number]: string };

  // dieFaces: Array<string>;
  rerollCount: number;
  earliestLockedDie: DieState;
  recentlyLockedDie: DieState;
  half: number;
  highscore: number;
  playerCategory: string;
  guessRound: number;
  // you: Player;
  // enemy: Player;
  reroll: () => void;
  generateNewThreshold: () => void;
  go: () => void;
  flipIndex: number;
  submitDie: () => void;

  restartGame: () => void;
  clear: () => void;

  flip: (dieIndex: number) => void;
  dragIn: (dieIndex: number, guessIndex: number) => void;
  swap: (dieIndex: number, guessIndex: number) => void;

  lockDie: (dieIndex: number, isPlayerDice: boolean) => void;
  gameResetCounter: number;
}

const MIN_THRESHOLD = 9;
const MAX_THRESHOLD = 15;
let count = 0;

function generateNewThreshold() {
  return (
    Math.floor(Math.random() * (MAX_THRESHOLD - MIN_THRESHOLD + 1)) +
    MIN_THRESHOLD
  );
}

let newWord: string;

// async function fetchRandomWord(): Promise<void> {
//   const response = await fetch("https://random-word-api.herokuapp.com/word");
//   const data = await response.json();
//   return data[0];
// }

// fetchRandomWord().then(() => {});

const diceArray = Array.from({ length: TOGGLES.numberOfDice }, (_, index) => ({
  value: Math.floor(Math.random() * TOGGLES.valueOfDice),
  word: index.toString(),
  emoji: index.toString(),
  locked: false,
  dieIndex: index,
  guess: "none",
}));

const emptyGuesses = Array.from(
  { length: TOGGLES.numberOfGuesses },
  (_, index) => ({
    value: -1,
    word: "   ",
    emoji: "",
    locked: false,
    dieIndex: -1,
    guess: "none",
  })
);

export const useGameplayStore = create<GameplayState, any>(
  persist(
    (set, state) => ({
      previousGuessStates: {},

      offPositionGuesses: {},
      playerAttack: {
        value: -1,
        word: "Welcome",
        emoji: "😼",
        body: "Press play to generate a clueword",
        effect: "Let's see what you got",
      },
      enemyAttack: {
        value: -1,
        emoji: "😼",
        word: "Welcome to Relations",
        body: "Press play to generate a clueword",
        effect: "Let's see what you got",
      },
      moveresult: {
        header: "Test",
        body: "test body",
      },
      half: 1,
      highscore: 0,
      playerCategory: "",
      guessedDice: emptyGuesses,
      flipIndex: 0,
      guessRound: 0,

      // you: {
      //   name: "You",
      //   health: 100,
      //   conditions: [],
      //   items: [],
      // },
      // enemy: {
      //   name: "Enemy",
      //   health: 100,
      //   conditions: [],
      //   items: [],
      // },

      rerollCount: TOGGLES.numberOfRerolls,
      earliestLockedDie: {} as DieState,
      recentlyLockedDie: {} as DieState,
      // dieFaces: ["⚀", "⚁", "⚂", "⚃", "⚄", "⚅"],
      // setDieFaces: (newFaces: Array<string>) => {
      //   set({ dieFaces: newFaces });
      // },

      swap: (dieIndex: number, guessIndex: number) => {
        set((state: GameplayState) => {
          const newGuessedDice = [...state.guessedDice];
          const newOffPositionGuesses = { ...state.offPositionGuesses };

          // Check if the die being picked up is correct
          if (
            newGuessedDice[dieIndex].guess === "correct" ||
            newGuessedDice[guessIndex].guess === "correct" ||
            state.guessRound > 4
          ) {
            return state; // Return without making any changes
          }

          // Swap the two dice
          const temp = newGuessedDice[dieIndex];
          newGuessedDice[dieIndex] = newGuessedDice[guessIndex];
          newGuessedDice[guessIndex] = temp;

          // Update the dieIndex property and locked state for the swapped dice
          if (newGuessedDice[dieIndex]) {
            newGuessedDice[dieIndex].dieIndex = state.playerDies.findIndex(
              (die) => die.word === newGuessedDice[dieIndex].word
            );
            if (newGuessedDice[guessIndex].locked && !temp.locked) {
              newGuessedDice[dieIndex].locked = true;
              newGuessedDice[guessIndex].locked = false;
            }
          }
          if (newGuessedDice[guessIndex]) {
            newGuessedDice[guessIndex].dieIndex = state.playerDies.findIndex(
              (die) => die.word === newGuessedDice[guessIndex].word
            );
          }

          // Update off-position guesses
          [dieIndex, guessIndex].forEach((index) => {
            const die = newGuessedDice[index];
            if (die.dieIndex !== -1) {
              if (newOffPositionGuesses[die.dieIndex]) {
                if (!newOffPositionGuesses[die.dieIndex].includes(index)) {
                  die.guess = "none";
                } else {
                  die.guess = "off";
                }
              }
            }
          });

          return {
            ...state,
            guessedDice: newGuessedDice,
            offPositionGuesses: newOffPositionGuesses,
          };
        });
      },
      dragIn: (dieIndex: number, guessIndex: number) => {
        set((state: GameplayState) => {
          const newPlayerDies = [...state.playerDies];
          const newGuesses = [...state.guessedDice];

          if (
            newPlayerDies[dieIndex].guess === "correct" ||
            newGuesses[guessIndex].guess === "correct" ||
            newPlayerDies[dieIndex].guess === "wrong" ||
            state.guessRound > 4
          ) {
            return state; // Return without making any changes
          }

          // Check if the die is already in guessedDice
          const existingIndex = newGuesses.findIndex(
            (die) => die.dieIndex === dieIndex
          );

          if (existingIndex !== -1) {
            // If it exists elsewhere, reset that position
            newGuesses[existingIndex] = {
              value: -1,
              word: "   ",
              emoji: "",
              locked: false,
              dieIndex: -1,
              guess: "",
            };
          }

          // Update the new position
          newGuesses[guessIndex] = {
            ...state.playerDies[dieIndex],
            locked: true,
            dieIndex: dieIndex,
          };

          // Unlock the corresponding player die if it has the same word
          const playerDieIndex = state.playerDies.findIndex(
            (die) =>
              die.word === state.guessedDice[guessIndex].word && die.locked
          );
          if (playerDieIndex !== -1) {
            newPlayerDies[playerDieIndex].locked = false;
          }

          // Lock the die in playerDies
          newPlayerDies[dieIndex] = {
            ...newPlayerDies[dieIndex],
            locked: true,
          };

          return {
            ...state,
            playerDies: newPlayerDies,
            guessedDice: newGuesses,
          };
        });
      },
      playerDies: diceArray,
      clear: () => {
        if (state().guessRound > 4) {
          return;
        }
        set((state: GameplayState) => {
          return {
            ...state,
            playerDies: state.playerDies.map((die) => ({
              ...die,
              locked:
                die.guess === "correct" || die.guess === "off"
                  ? die.locked
                  : false,
            })),
            guessedDice: state.guessedDice.map((die) =>
              die.guess === "correct" || die.guess === "off"
                ? die
                : {
                    value: -1,
                    word: "   ",
                    emoji: "",
                    locked: false,
                    dieIndex: -1,
                    guess: "none",
                  }
            ),
          };
        });
      },
      flip: (dieIndex: number) => {
        set((state: GameplayState) => {
          if (state.flipIndex === -1) {
            return {
              ...state,
              flipIndex: dieIndex,
            };
          } else {
            if (state.flipIndex != dieIndex) {
              const newGuesses = [...state.guessedDice];
              const temp = newGuesses[state.flipIndex];
              newGuesses[state.flipIndex] = newGuesses[dieIndex];
              newGuesses[dieIndex] = temp;
              return {
                ...state,
                guessedDice: newGuesses,
                flipIndex: -1,
              };
            } else {
              return {
                ...state,
                flipIndex: -1,
              };
            }
          }
          // return {
          //   ...state,
          //   flipIndex: state.flipIndex === -1 ? dieIndex : state.flipIndex,
          //   playerDies: state.playerDies.map((die) => ({
          //     ...die,
          //     locked: false,
          //   })),
          //   // guessedDice: emptyGuesses,
          // };
        });
      },
      reroll: () => {
        set((state: GameplayState) => {
          return {
            rerollCount: state.rerollCount - 1,
            playerDies: state.playerDies.map((die: DieState, index: number) => {
              let wordIndex = Math.floor(Math.random() * words.length);
              while (
                state.rerollCount > 0 &&
                index <= TOGGLES.numberOfDice &&
                !die.locked
              ) {
                const newWordIndex = Math.floor(Math.random() * words.length);
                if (newWordIndex !== wordIndex) {
                  wordIndex = newWordIndex;
                  break;
                }
              }
              if (state.rerollCount > 0 && index <= TOGGLES.numberOfDice) {
                if (!die.locked) {
                  return {
                    value: (Math.floor(Math.random() * TOGGLES.valueOfDice) +
                      1) as 1 | 2 | 3 | 4 | 5 | 6,
                    word: words[wordIndex].word,
                    emoji: words[wordIndex].emoji,
                    locked: die.locked,
                    dieIndex: die.dieIndex,
                    guess: "none",
                  };
                }
              }
              return die;
            }),
          };
        });
      },

      threshold: 2,
      generateNewThreshold: () => {
        set({ threshold: generateNewThreshold() });
      },

      submitDie: async () => {
        if (state().guessedDice.some((die) => die.dieIndex !== -1)) {
          state().clear();
        }
        if (
          state().guessRound >= 4 ||
          state().guessedDice.every((die) => die.guess === "correct")
        ) {
          state().restartGame();
        }
        // console.log(state().playerCategory)
        let newEarliestLockedDie = state().earliestLockedDie;
        let newRecentlyLockedDie = state().recentlyLockedDie;
        state().half = state().half + 1;
        const lockedDieCount = state().playerDies.filter(
          (die) => die.locked
        ).length;
        // if (lockedDieCount !== 2) {
        //   alert("Select two die!");
        //   return;
        // }
        set((state: GameplayState) => ({
          ...state,
          playerAttack: {
            value: -1,
            emoji: "💭",
            word: "Generating Word...",
            body: "",
            effect: "Get ready to think!",
          },
          enemyAttack: {
            value: -1,
            emoji: "💭",
            word: "Generating Word...",
            body: "",
            effect: "Get ready to think!",
          },
        }));

        const diceValuesAndWords = state().playerDies.map((die) => ({
          value: die.value,
          word: die.word,
          emoji: die.emoji,
        }));
        // console.log(diceValuesAndWords)

        // This is the code that will be used to generate the attack
        try {
          const diceValuesAndWordsString = state()
            .playerDies.map((die) => `${die.value}: ${die.word}`)
            .join("\n");
          const response = await fetch(CLAUDE_PROXY_URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              model: "claude-3-5-sonnet-20240620",
              // model: "claude-3-opus-20240229",

              max_tokens: 1000,
              temperature: 0,
              messages: [
                {
                  role: "user",
                  content: `You're a game master for a Codenames-inspired word-guessing game. Create a round based on these words, emojis, and point values:

${diceValuesAndWordsString}

Steps:
1. Analyze the word list for connections.
2. Choose a single-word clue phrase related to multiple words, but not containing any list words.
3. Select ${TOGGLES.numberOfGuesses} target words:
   - At least 2 easy, 1 medium
   - Use provided emojis
   - Ensure logical connections
   - Avoid opposites of the clue - if there is an opposite, regenerate a better clue
   - Make clue specific and concrete
4. Sum target word point values.
5. Make sure every single word in the target list makes sense in the context of the clue. If it doesn't, regenerate a better clue using only words from playerDie.

Output JSON:
{
    "clue_word": "...",
    "total_points": ...,
    "emoji": "...",
    "Words": {
        "Word": [...],
        "Reasoning": [...],
        "DifficultyValue": [...],
        "Difficulty": [...],
        "Value": [...],
        "Emoji": [...],
        "Earned": [false, false, ...]
    }
}

Here's a list of good connections:

VISUAL INTERFACE: DISPLAY, MONITOR, SCREEN, TERMINAL

BURLESQUE WEAR: BOA, CORSET, FAN, GLOVES

BEIGE SHADES: BUFF, CREAM, FAWN, TAN

LANGUAGE HOMOPHONES: BASK, CHECK, FINISH, TIE

CLASSIC MOVIE THEATER EQUIPMENT: PROJECTOR, REEL, SCREEN, SPEAKER

TIER: DECK, FLOOR, LEVEL, STORY

NEWSPAPER NAMES: GLOBE, MIRROR, POST, SUN

PRANK VERBS: EGG, MOON, STREAK, TOILET PAPER

COVER WITH A THICK LAYER: CAKE, COAT, PLASTER, SMEAR

THINGS THAT ARE INFLATED: BALLOON, BASKETBALL, FLOATIE, TIRE

KINDS OF CHARTS: BAR, BUBBLE, LINE, PIE

CLASSIC COCKTAIL TYPES: FIZZ, PUNCH, SLING, SOUR

RUSH OF WIND: BLOW, DRAFT, GUST, PUFF

ROTATE: CRANK, REEL, TURN, WIND

ZODIAC SYMBOLS: BULL, CRAB, LION, RAM

DR. SEUSS TITLE FIGURES: CAT, GRINCH, POP, TURTLE

MUSIC GENRES: BLUES, COUNTRY, FOLK, ROCK

BRATWURST GO-WITHS: BRAT, BUN, MUSTARD, SAUERKRAUT

YOGA POSES: CHAIR, MOUNTAIN, TREE, WARRIOR

STARTING WITH PIXAR MOVIES: COCONUT, SOULMATE, UPDO, WALLET

ALARM CLOCK BUTTONS: ALARM, HOUR, SNOOZE, TIME SET

"HERE'S A THOUGHT ...": PERHAPS, SAY, SUPPOSE, WHAT IF

CANDY PIECES: KISS, NERD, RUNT, WHOPPER

SEVEN DWARFS MINUS LAST LETTER: DO, DOPE, GRUMP, SLEEP

SPOKEN COMMUNICATION: DIALECT, LANGUAGE, SPEECH, TONGUE

CANOODLE: FRENCH, KISS, MAKE OUT, NECK

FIRST AID KIT ITEMS: BANDAGE, DRESSING, SCISSORS, TAPE

HOUSE STYLES: COTTAGE, CRAFTSMAN, PRAIRIE, RANCH

MEH: AVERAGE, FAIR, OK, SO-SO

U.S. STATE ABBREVIATIONS: HI, MA, OR, PA

KINDS OF BOOTS: ANKLE, COWBOY, GO-GO, THIGH-HIGH

THINGS THAT GO UP AND DOWN: ELEVATOR, SUN, TIDE, YO-YO

BREADTH: EXTENT, RANGE, REACH, SCOPE

OTHER HALF: COMPLEMENT, MATCH, MATE, PARTNER

BEERS, FAMILIARLY: BUD, NATTY, SIERRA, STELLA

WORDS AFTER "PAY": CHECK, DIRT, PAL, PHONE

BLUNDER: BOO-BOO, FLUB, GAFFE, GOOF

FLOWERS: DAISY, JASMINE, PETUNIA, POPPY

THINGS BEES DO: BUZZ, DANCE, POLLINATE, STING

FIRST NAMES OF YANKEES LEGENDS: BABE, LOU, MICKEY, YOGI

STATED: SAID, SPOKE, TOLD, VOICED

NICKNAME: DESIGNATION, HANDLE, MONIKER, SOBRIQUET

OBVIOUS: CLEAR, MARKED, PRONOUNCED, STRIKING

___HOUSE (THAT AREN'T HOUSES): PORTER, POWER, ROUGH, WHEEL

METAL ELEMENTS: GOLD, LEAD, MERCURY, TIN

MATTRESS SIZES: FULL, KING, QUEEN, TWIN

SLANG FOR TOILET: CAN, HEAD, JOHN, THRONE

KINDS OF KNIVES: BOWIE, BUTCHER, BUTTER, BUTTERFLY

LARGE BOAT: BARGE, CRAFT, SHIP, VESSEL

ALL-TIME GREAT: BEST, CHAMP, GOAT, LEGEND

REGARDING: ABOUT, CONCERNING, ON, TOWARD

HOMOPHONES OF BODY FEATURES: HARE, I, MUSSEL, NAVAL

THINGS THAT ARE RED: DEVIL, MARS, ROSE, STRAWBERRY

BE IN COMMISSION: FUNCTION, OPERATE, RUN, WORK

MICROSOFT PRODUCTS: EDGE, OFFICE, TEAMS, WINDOWS

BIOPICS: BLONDE, JOBS, MILK, VICE

NOT PRESENT: ABSENT, ELSEWHERE, GONE, MIA

SUPPORTER: ADVOCATE, CHAMPION, CHEERLEADER, EXPONENT

ROCK GENRES: GLAM, GOTH, METAL, PUNK

SHOCK ___: HORROR, JOCK, VALUE, WAVE

SPLASHY WAYS TO ENTER A POOL: BACKFLIP, BELLYFLOP, CANNONBALL, JACKKNIFE

REITERATE: ECHO, PARROT, REPEAT, QUOTE

MAINSTAY: ANCHOR, BACKBONE, CORNERSTONE, PILLAR

___ RADIO: HAM, PIRATE, SATELLITE, TALK

CRAM: JAM, PACK, RAM, STUFF

RESTAURANT JOBS: COOK, DISHWASHER, HOST, SERVER

ELECTROMAGNETIC SPECTRUM: MICROWAVE, RADIO, VISIBLE, X-RAY

THINGS WITH BITS: BRIDLE, BYTE, COMEDIAN, DRILL

FLUFFY WHITE THINGS: CLOUD, COTTON BALL, DANDELION, SHEEP

ONE IN A RESEARCH STUDY: GUINEA PIG, PARTICIPANT, SUBJECT, VOLUNTEER

TAKE ISSUE: DISAPPROVE, MIND, OBJECT, PROTEST

___HORN: BULL, FOG, MATTER, SHOE

SURVIVE: GET BY, HACK IT, MAKE DO, MANAGE

KINDS OF GREETING CARDS: BIRTHDAY, CONGRATULATIONS, GET WELL, THANK YOU

ARE WE CLEAR?: CAPISCE, GET IT, SEE, UNDERSTAND

WORDS AFTER "EYE": CANDY, CONTACT, SHADOW, WITNESS

KIDDO: BUDDY, CHAMP, SPORT, TIGER

UP FOR IT: AMENABLE, DOWN, GAME, WILLING

BEGINNING WITH DOUBLE LETTERS: AARDVARK, EERIE, LLAMA, OOZE

NICKNAMES FOR THE SEA, WITH "THE": BLUE, BRINY, DEEP, DRINK

BIT OF EXCITEMENT: HIGH, KICK, RUSH, THRILL

TOLERATE: BEAR, STAND, STOMACH, TAKE

NONSENSE: BALONEY, BUNK, TRIPE

THINGS TO CLICK: HEELS, MOUSE, REMOTE, TONGUE

STRETCH, AS ONE'S PATIENCE: STRAIN, TAX, TEST, TRY

THINGS A DJ DOES: MIX, SAMPLE, SCRATCH, SPIN

ADJECTIVES USED IN GRAMMAR: DIRECT, IRREGULAR, POSSESSIVE, PRESENT

WHAT "GREEN" MIGHT MEAN: ECOLOGICAL, JEALOUS, LUSH, NAUSEOUS

PROMOTE: HYPE, MARKET, PITCH, SELL

THROW: CAST, CHUCK, FLING, HURL

BOOKSTORE SECTIONS: FICTION, HUMOR, ROMANCE, TRAVEL

___ SOCKS: ANKLE, CREW, SWEAT, TUBE

Try to have your outputs be as good as these examples. 

Ensure all words in the output exist in the playerDie array. If not, regenerate a better clue using only words from playerDie. Optimize the clueword for easy guessing. Output only the JSON object.
After generating the clue, test it out and play as if you are a middle schooler. If you think it's too hard, regenerate a better clue using only words from playerDie.
`,
                },
              ],
            }),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          const generatedMove = data.content[0].text;
          const parsedMove = JSON.parse(generatedMove);
          console.log(parsedMove);

          set({
            ...state(),
            enemyAttack: {
              value: parsedMove.Words.Word.length,
              emoji: parsedMove.emoji, // Assuming you want to keep this logic
              word: parsedMove.clue_word,
              body: "",
              effect: "",
            },
            playerAttack: {
              value: parsedMove.Words.total_points,
              emoji: parsedMove.emoji, // Assuming you want to keep this logic
              word: parsedMove.clue_word,
              body: "",

              effect: parsedMove,
            },
          });
        } catch (error) {
          console.error("Error:", error);
        }
      },

      restartGame: () => {
        set((state) => ({
          half: 1,
          playerDies: state.playerDies.map((die) => {
            let newIndex: number;
            let attempts = 0;
            const maxAttempts = words.length * 2; // Safeguard against infinite loop

            do {
              newIndex = Math.floor(Math.random() * words.length);
              attempts++;

              if (attempts > maxAttempts) {
                console.error(
                  "Unable to find a unique word. Resetting all selections."
                );
                usedIndices.clear();
                currentlyAssignedWords.clear();
                attempts = 0;
              }
            } while (
              usedIndices.has(newIndex) ||
              state.playerDies.some(
                (d: DieState) => d.word === words[newIndex].word
              ) ||
              currentlyAssignedWords.has(words[newIndex].word)
            );

            usedIndices.add(newIndex);
            currentlyAssignedWords.add(words[newIndex].word);

            // If all words have been used, reset the usedIndices
            if (usedIndices.size === words.length) {
              usedIndices.clear();
              currentlyAssignedWords.clear();
            }
            return {
              value: (Math.floor(Math.random() * TOGGLES.valueOfDice) + 1) as
                | 1
                | 2
                | 3
                | 4
                | 5
                | 6,
              word: words[newIndex].word,
              emoji: words[newIndex].emoji,
              locked: false,
              dieIndex: die.dieIndex,
              guess: "none",
            };
          }),

          playerAttack: {
            value: -1,
            word: "Welcome",
            emoji: "😼",
            body: "Press play to generate a clueword",
            effect: "Let's see what you got",
          },
          enemyAttack: {
            value: -1,
            emoji: "😼",
            word: "Welcome to Proximo",
            body: "",
            effect: "Let's see what you got",
          },
          rerollCount: TOGGLES.numberOfRerolls,
          threshold: generateNewThreshold(),
          guessedDice: emptyGuesses,
          flipIndex: -1,
          guessRound: 0,
          offPositionGuesses: {},
          gameResetCounter: state.gameResetCounter + 1,
        }));
      },
      go: async () => {
        console.log(state().guessRound);
        set((state) => {
          if (typeof state.playerAttack.effect === "object") {
            const targetWords = state.playerAttack.effect.Words.Word;
            const newOffPositionGuesses = { ...state.offPositionGuesses };

            const newGuessedDice = state.guessedDice.map((die, guessIndex) => {
              if (die.value !== -1) {
                const targetIndex = targetWords.indexOf(die.word);
                if (targetIndex === -1) {
                  // Word not in target words, remove from guessed dice
                  return {
                    value: -1,
                    word: "   ",
                    emoji: "",
                    locked: false,
                    dieIndex: -1,
                    guess: "none",
                  };
                } else if (targetIndex === guessIndex) {
                  // Correct position
                  return { ...die, locked: true, guess: "correct" };
                } else {
                  // Off position
                  if (!newOffPositionGuesses[die.dieIndex]) {
                    newOffPositionGuesses[die.dieIndex] = [];
                  }
                  if (
                    !newOffPositionGuesses[die.dieIndex].includes(guessIndex)
                  ) {
                    newOffPositionGuesses[die.dieIndex].push(guessIndex);
                  }
                  return { ...die, locked: true, guess: "off" };
                }
              }
              return die;
            });

            const newPlayerDies = state.playerDies.map((die) => {
              const guessedDie = newGuessedDice.find(
                (guessDie) => guessDie.dieIndex === die.dieIndex
              );
              if (guessedDie && guessedDie.value !== -1) {
                return {
                  ...die,
                  guess: guessedDie.guess,
                  locked: guessedDie.locked,
                };
              }
              // If the die was guessed but is now removed (wrong guess), mark it as wrong
              if (
                state.guessedDice.some(
                  (guessDie) => guessDie.dieIndex === die.dieIndex
                )
              ) {
                return { ...die, guess: "wrong", locked: false };
              }
              return die; // Keep the original state for dice not involved in the guess
            });

            // Update the effect to mark correct guesses
            const updatedEffect = { ...state.playerAttack.effect };
            updatedEffect.Words.Earned = updatedEffect.Words.Word.map((word) =>
              newGuessedDice.some(
                (die) => die.word === word && die.guess === "correct"
              )
            );

            return {
              guessedDice: newGuessedDice,
              playerDies: newPlayerDies,
              playerAttack: {
                ...state.playerAttack,
                effect: updatedEffect,
              },
              offPositionGuesses: newOffPositionGuesses,
            };
          }
          return state;
        });

        set((state) => {
          const allCorrect = state.guessedDice.every(
            (die) => die.guess === "correct"
          );

          const newGuessRound = state.guessRound + 1;

          if (allCorrect) {
            return {
              half: state.half + 1,
              playerDies: state.playerDies.map((die) => ({
                ...die,
                locked: false,
              })),
              playerAttack: {
                ...state.playerAttack,
                body: "Select a word to see the reasoning",
              },
              enemyAttack: {
                value: state.playerAttack.value,
                emoji: state.playerAttack.emoji,
                word: state.playerAttack.word,
                body: state.playerAttack.body,
                effect: state.playerAttack.effect,
              },
              guessRound: newGuessRound,
            };
          }

          // this should be the logic for see results
          if (newGuessRound >= 5) {
            console.log(state.guessRound);

            // Game over at exactly 5 rounds
            const updatedPlayerDies = state.playerDies.map((die) => ({
              ...die,
              locked: false,
            }));

            const targetWords = Array.isArray(state.playerAttack.effect)
              ? state.playerAttack.effect
              : typeof state.playerAttack.effect === "object" &&
                state.playerAttack.effect.Words?.Word
              ? state.playerAttack.effect.Words.Word
              : [];

            const updatedGuessedDice = new Array(targetWords.length).fill(null);

            state.guessedDice.forEach((die) => {
              if (die.guess === "correct" || die.guess === "off") {
                const index = targetWords.indexOf(die.word);
                if (index !== -1) {
                  updatedGuessedDice[index] = { ...die };
                }
              }
            });

            targetWords.forEach((word, index) => {
              if (!updatedGuessedDice[index]) {
                const matchingDieIndex = updatedPlayerDies.findIndex(
                  (die) => die.word === word
                );
                if (matchingDieIndex !== -1) {
                  updatedGuessedDice[index] = {
                    ...updatedPlayerDies[matchingDieIndex],
                    locked: true,
                    guess: "missing",
                  };
                  updatedPlayerDies[matchingDieIndex] = {
                    ...updatedPlayerDies[matchingDieIndex],
                    guess: "missing",
                  };
                }
              }
            });

            return {
              half: state.half + 1,
              playerDies: updatedPlayerDies,
              guessedDice: updatedGuessedDice,
              playerAttack: {
                ...state.playerAttack,
                body: "Select a word to see the reasoning",
              },
              enemyAttack: {
                value: state.playerAttack.value,
                emoji: state.playerAttack.emoji,
                word: state.playerAttack.word,
                body: state.playerAttack.body,
                effect: state.playerAttack.effect,
              },
              guessRound: newGuessRound,
            };
          }

          return {
            ...state,
            guessRound: newGuessRound,
          };
        });
      },
      lockDie: (dieIndex: number) => {
        set((state) => {
          if (state.guessRound > 4) {
            // If player is clicking amongst the player dice array, return early
            if (
              state.playerDies.some((die) => die.dieIndex === dieIndex) &&
              !state.guessedDice.some((die) => die.dieIndex === dieIndex)
            ) {
              return state;
            }
            const selectedDie = state.playerDies[dieIndex];
            if (selectedDie) {
              const isDeselecting = state.guessedDice.some(
                (die) => die.dieIndex === dieIndex && die.guess === "blue"
              );

              return {
                ...state,
                enemyAttack: {
                  ...state.enemyAttack,
                  emoji: isDeselecting
                    ? state.playerAttack.emoji
                    : selectedDie.emoji,
                  word: isDeselecting
                    ? state.playerAttack.word
                    : selectedDie.word,
                  body: isDeselecting
                    ? "Select a word to see the reasoning"
                    : typeof state.playerAttack.effect === "object"
                    ? state.playerAttack.effect.Words.Reasoning[
                        state.playerAttack.effect.Words.Word.indexOf(
                          selectedDie.word
                        )
                      ] || "No reasoning available for this word"
                    : "Select a word to see the relation",
                },
                guessedDice: state.guessedDice.map((die) => {
                  if (die.dieIndex === dieIndex) {
                    // If this die is being clicked
                    if (die.guess === "blue") {
                      // If deselecting, return to the original state
                      const originalDie = state.playerDies.find(
                        (d) => d.dieIndex === dieIndex
                      );
                      return {
                        ...die,
                        guess: originalDie ? originalDie.guess : "none",
                      };
                    } else {
                      // If selecting, mark as blue
                      return { ...die, guess: "blue" };
                    }
                  } else if (die.guess === "blue") {
                    // If this is a different die that was previously blue
                    const originalDie = state.playerDies.find(
                      (d) => d.dieIndex === die.dieIndex
                    );
                    return {
                      ...die,
                      guess: originalDie ? originalDie.guess : "none",
                    };
                  }
                  // Keep other dice unchanged
                  return die;
                }),
              };
            }
            return state;
          }

          if (
            dieIndex < 0 ||
            dieIndex >= state.playerDies.length ||
            ["wrong", "off", "correct"].includes(
              state.playerDies[dieIndex].guess
            )
          ) {
            return state;
          }

          const maxLockedDice = TOGGLES.numberOfGuesses;
          const currentDie = state.playerDies[dieIndex];
          const isLocking = !currentDie.locked;

          const lockedCount = state.playerDies.filter(
            (die) => die.locked
          ).length;

          if (isLocking && lockedCount >= maxLockedDice) {
            return state;
          }

          const newPlayerDies = state.playerDies.map((die, index) =>
            index === dieIndex ? { ...die, locked: !die.locked } : die
          );

          let newGuessedDice = [...state.guessedDice];
          if (isLocking) {
            const emptyIndex = newGuessedDice.findIndex(
              (die) => die.value === -1
            );
            if (emptyIndex !== -1) {
              newGuessedDice[emptyIndex] = { ...currentDie, locked: true };
            }
          } else {
            const guessedDieIndex = newGuessedDice.findIndex(
              (die) =>
                die.value === currentDie.value && die.dieIndex === dieIndex
            );
            if (guessedDieIndex !== -1) {
              newGuessedDice[guessedDieIndex] = {
                value: -1,
                word: "   ",
                emoji: "",
                locked: false,
                dieIndex: -1,
                guess: "none",
              };
            }
          }

          return {
            playerDies: newPlayerDies,
            guessedDice: newGuessedDice,
          };
        });
      },
      gameResetCounter: 0,
    }),
    {
      name: "gameplay-store", // unique name of the store
      // getStorage: () => sessionStorage, // (optional) by default the 'localStorage' is used
    }
  )
);
