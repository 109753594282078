import React, { useState, useEffect } from "react";
import { Items as wordsFromFile } from "../store/wordLists/Codenames";
import Anthropic from "@anthropic-ai/sdk";

// Utility functions
const getRandomInt = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1)) + min;
const shuffleArray = <T,>(array: T[]): T[] =>
  [...array].sort(() => Math.random() - 0.5);

// Update the WordItem interface
interface WordItem {
  word: string;
  score: number;
  emoji: string; // Add emoji property
  reasoning?: string; // Add reasoning property
}

// Update the getRandomWords function to select from a fixed set
const getRandomWords = (count: number): WordItem[] => {
  if (
    !getRandomWords.fixedWordSet ||
    getRandomWords.fixedWordSet.length === 0
  ) {
    getRandomWords.fixedWordSet = shuffleArray(wordsFromFile)
      .slice(0, 16)
      .map((word) => ({
        ...word,
        score: 0, // Initialize score to 0
      }));
  }

  return getRandomWords.fixedWordSet.slice(0, count);
};

// Update the static property type
getRandomWords.fixedWordSet = [] as WordItem[];

// Sample data (now dynamically populated)
const keywords = getRandomWords(1);
const wordPool = getRandomWords(25);

function levenshteinDistance(a: string, b: string): number {
  const matrix = Array(b.length + 1)
    .fill(null)
    .map(() => Array(a.length + 1).fill(null));

  for (let i = 0; i <= a.length; i++) matrix[0][i] = i;
  for (let j = 0; j <= b.length; j++) matrix[j][0] = j;

  for (let j = 1; j <= b.length; j++) {
    for (let i = 1; i <= a.length; i++) {
      const substitutionCost = a[i - 1] === b[j - 1] ? 0 : 1;
      matrix[j][i] = Math.min(
        matrix[j][i - 1] + 1,
        matrix[j - 1][i] + 1,
        matrix[j - 1][i - 1] + substitutionCost
      );
    }
  }

  return matrix[b.length][a.length];
}

const CLAUDE_PROXY_URL = "https://claude-worker.mmmpage.workers.dev/";
const anthropic = new Anthropic({
  apiKey:
    "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", // defaults to process.env["ANTHROPIC_API_KEY"]
  dangerouslyAllowBrowser: true,
});

export const NumberGridGame = () => {
  const [keyword, setKeyword] = useState("");
  const [currentRound, setCurrentRound] = useState(1);
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(100);
  const [words, setWords] = useState<WordItem[]>([]);
  const [selectedWords, setSelectedWords] = useState<WordItem[]>([]);
  const [gameOver, setGameOver] = useState(false);
  const [roundTileCounts, setRoundTileCounts] = useState<number[]>([]);
  const [wordPositions, setWordPositions] = useState<(WordItem | null)[]>([]);
  const [isHardMode, setIsHardMode] = useState(true);
  const [allWords, setAllWords] = useState<WordItem[]>([]);
  const [gameStarted, setGameStarted] = useState(false);
  const [initialWords, setInitialWords] = useState<WordItem[]>([]);
  const [isPeekEnabled, setIsPeekEnabled] = useState(false);
  const [isPeeking, setIsPeeking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [gameActive, setGameActive] = useState(false);
  const [isGameGenerated, setIsGameGenerated] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [showRationale, setShowRationale] = useState<{
    [key: string]: boolean;
  }>({});
  const [keywordEmoji, setKeywordEmoji] = useState("");

  useEffect(() => {
    // Remove the automatic game preparation
    // prepareNewGame();
  }, []);

  useEffect(() => {
    if (gameActive && timeLeft > 0 && !gameOver && selectedWords.length < 5) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 30);
      return () => clearTimeout(timer);
    } else if (
      gameActive &&
      timeLeft === 0 &&
      currentRound < 5 &&
      selectedWords.length < 5
    ) {
      startNextRound();
    } else if (gameActive) {
      endGame();
    }
  }, [timeLeft, currentRound, gameOver, selectedWords.length, gameActive]);

  useEffect(() => {
    const currentTileCount = roundTileCounts[currentRound - 1] || 0;
    const selectedWords = shuffleArray(words.slice(0, currentTileCount));
    const positions = Array(5).fill(null);

    selectedWords.forEach((word) => {
      let index;
      do {
        index = Math.floor(Math.random() * 5);
      } while (positions[index] !== null);
      positions[index] = word;
    });

    setWordPositions(positions);
  }, [currentRound, words, roundTileCounts]);

  const generateGame = () => {
    setIsLoading(true);
    setIsGameGenerated(false);
    prepareNewGame();
  };

  const prepareNewGame = () => {
    setIsResetting(true);
    setIsLoading(true);
    setIsGameGenerated(false);

    getRandomWords.fixedWordSet = []; // Clear the fixed word set

    // Get 51 unique words (1 keyword + 50 game words)
    const allWords = getRandomWords(51);
    console.log("allWords", allWords);

    // The first word becomes the keyword
    const newKeyword = allWords[0];
    setKeyword(newKeyword.word);
    setKeywordEmoji(newKeyword.emoji);

    // The rest become the game words
    const gameWords = allWords.slice(1);

    setCurrentRound(1);
    setScore(0);
    setTimeLeft(100);
    setSelectedWords([]);
    setGameOver(false);
    setGameStarted(false);
    setRoundTileCounts(
      isHardMode ? [2, 4, 3, 4, 2] : shuffleArray([1, 2, 3, 4, 5])
    );

    setInitialWords(gameWords);
    prepareWords(newKeyword.word, gameWords);
  };

  const prepareWords = async (newKeyword: string, gameWords: WordItem[]) => {
    try {
      const response = await fetch(CLAUDE_PROXY_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          model: "claude-3-sonnet-20240229",
          max_tokens: 1000,
          temperature: 0,
          messages: [
            {
              role: "user",
              content: `You are an AI assistant helping to score words for a word association game. The keyword is "${newKeyword}". Please evaluate the following list of words and assign a score from 0 to 100 based on their relevance to the keyword. A score of 100 means the word is very closely related, while 0 means there's no apparent relation.

Words to evaluate:
${gameWords.map((word) => word.word).join(", ")}

Please provide your response as a JSON object with the following structure:
{
  "scoredWords": [
    {
      "word": "example",
      "score": 50,
      "reasoning": "Brief explanation of the score"
    },
    // ... more words ...
  ]
}

Ensure that your reasoning is concise and focuses on the relationship between the word and the keyword.`,
            },
          ],
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const scoredWordsData = JSON.parse(data.content[0].text);

      console.log("Claude's response:", scoredWordsData);

      const scoredGameWords = gameWords.map((wordObj) => {
        const scoredWord = scoredWordsData.scoredWords.find(
          (sw: { word: string }) =>
            sw.word.toLowerCase() === wordObj.word.toLowerCase()
        );
        return {
          ...wordObj,
          score: scoredWord ? scoredWord.score : 0,
          reasoning: scoredWord ? scoredWord.reasoning : "",
        };
      });

      // Sort the scored words by score in descending order and take the top 15
      const top15Words = scoredGameWords
        .sort((a, b) => b.score - a.score)
        .slice(0, 15);

      setWords(top15Words);
      setAllWords(top15Words);
      setIsLoading(false);
      setIsGameGenerated(true);
      setIsResetting(false);
    } catch (error) {
      console.error("Error scoring words:", error);
      // Fallback to the original scoring method if there's an error
      const scoredGameWords = gameWords.map((wordObj) => {
        const distance = levenshteinDistance(
          newKeyword.toLowerCase(),
          wordObj.word.toLowerCase()
        );
        const maxLength = Math.max(newKeyword.length, wordObj.word.length);
        const similarity = 1 - distance / maxLength;
        return {
          ...wordObj,
          score: Math.round(similarity * 100),
        };
      });

      // Sort the scored words by score in descending order and take the top 15
      const top15Words = scoredGameWords
        .sort((a, b) => b.score - a.score)
        .slice(0, 15);

      setWords(top15Words);
      setAllWords(top15Words);
      setIsLoading(false);
      setIsGameGenerated(true);
      setIsResetting(false);
    }
  };

  const startNextRound = () => {
    setCurrentRound((prev) => prev + 1);
    setTimeLeft(100);
    setWords(shuffleArray(words.slice(roundTileCounts[currentRound - 1])));
  };

  const selectWord = (word: WordItem) => {
    if (selectedWords.length < 5 && !selectedWords.includes(word)) {
      setSelectedWords([...selectedWords, word]);
      setScore((prev) => prev + word.score);
      setWordPositions(wordPositions.map((w) => (w === word ? null : w)));
    }
  };

  const endGame = () => {
    setGameOver(true);
    setTimeLeft(0);
    setGameActive(false);
  };

  const startGame = () => {
    if (isLoading) return;

    if (isPeekEnabled) {
      setIsPeeking(true);
      setTimeout(() => {
        setIsPeeking(false);
        setGameStarted(true);
        setGameActive(true);
        setCurrentRound(1);
        setTimeLeft(100);
      }, 1000); // Peek lasts for 1 second
    } else {
      setGameStarted(true);
      setGameActive(true);
      setCurrentRound(1);
      setTimeLeft(100);
    }
  };

  const toggleRationale = (word: string) => {
    setShowRationale((prev) => ({
      ...prev,
      [word]: !prev[word],
    }));
  };

  const renderWords = () => (
    <div className="grid grid-cols-5 gap-4">
      {wordPositions.map((word, index) =>
        word ? (
          <button
            key={index}
            onClick={() => selectWord(word)}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition transform hover:scale-105 flex flex-col items-center justify-center h-24 w-full"
            disabled={gameOver || selectedWords.length >= 5}
          >
            <span className="text-2xl mb-1">{word.emoji}</span>
            <span>{word.word}</span>
          </button>
        ) : (
          <div key={index} className="bg-gray-200 rounded h-24 w-full"></div>
        )
      )}
    </div>
  );

  const renderPeekWords = () => (
    <div className="grid grid-cols-5 gap-4">
      {Array.from({ length: 15 }, (_, index) => (
        <div
          key={index}
          className="bg-gray-100 rounded-lg shadow-md p-2 flex flex-col items-center justify-center h-24 w-full"
        >
          {initialWords[index] && (
            <>
              <span className="text-2xl mb-1">{initialWords[index].emoji}</span>
              <span className="text-sm font-semibold">
                {initialWords[index].word}
              </span>
            </>
          )}
        </div>
      ))}
    </div>
  );

  const renderSelectedWords = () => (
    <div className="grid grid-cols-5 gap-4 mb-4">
      {selectedWords.map((word, index) => (
        <div
          key={index}
          onClick={() => toggleRationale(word.word)}
          className="bg-green-100 rounded-lg shadow-md p-2 flex flex-col items-center justify-center h-24 w-full cursor-pointer transition-all duration-300 ease-in-out"
        >
          {showRationale[word.word] ? (
            <p className="text-xs overflow-y-auto h-full w-full flex items-center justify-center text-center">
              {word.reasoning}
            </p>
          ) : (
            <>
              <span className="text-2xl mb-1">{word.emoji}</span>
              <span className="text-sm font-semibold">{word.word}</span>
              <span className="text-xs text-gray-600">{word.score} pts</span>
            </>
          )}
        </div>
      ))}
    </div>
  );

  const renderGameOver = () => {
    const sortedWords = [...allWords].sort((a, b) => b.score - a.score);
    const selectedWordIds = new Set(selectedWords.map((w) => w.word));

    return (
      <div className="text-center">
        <h2 className="text-2xl font-bold mb-4">Game Over</h2>
        <p className="text-xl mb-2">Final Score: {score}</p>

        <h3 className="text-xl font-bold mb-2">Keyword:</h3>
        <div className="flex justify-center mb-4">
          <div className="bg-yellow-100 rounded-lg shadow-md p-2 flex flex-col items-center justify-center h-24 w-24">
            <span className="text-3xl mb-1">{keywordEmoji}</span>
            <span className="text-sm font-semibold">{keyword}</span>
          </div>
        </div>

        <h3 className="text-xl font-bold mb-2">Selected Words:</h3>
        {renderSelectedWords()}

        <h3 className="text-xl font-bold mb-2">All Words (Ranked):</h3>
        <div className="grid grid-cols-5 gap-4 mb-4">
          {sortedWords.map((word, index) => (
            <div
              key={index}
              onClick={() => toggleRationale(word.word)}
              className={`${
                selectedWordIds.has(word.word) ? "bg-green-100" : "bg-white"
              } rounded-lg shadow-md p-2 flex flex-col items-center justify-center h-24 w-full cursor-pointer transition-all duration-300 ease-in-out`}
            >
              {showRationale[word.word] ? (
                <p className="text-xs overflow-y-auto h-full w-full flex items-center justify-center text-center">
                  {word.reasoning}
                </p>
              ) : (
                <>
                  <span className="text-2xl mb-1">{word.emoji}</span>
                  <span className="text-sm font-semibold">{word.word}</span>
                  <span className="text-xs text-gray-600">
                    {word.score} pts
                  </span>
                </>
              )}
            </div>
          ))}
        </div>

        <div className="flex justify-center items-center mt-4">
          <button
            onClick={generateGame}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition transform hover:scale-105"
            disabled={isLoading}
          >
            {isLoading ? "Generating..." : "Generate New Game"}
          </button>
        </div>
      </div>
    );
  };

  const renderInitialWords = () => (
    <div className="grid grid-cols-5 gap-4 mb-4">
      {Array.from({ length: 15 }, (_, index) => (
        <div
          key={index}
          className="bg-gray-100 rounded-lg shadow-md p-2 flex flex-col items-center justify-center h-24 w-full"
        >
          {isPeeking && initialWords[index] ? (
            <>
              <span className="text-2xl mb-1">{initialWords[index].emoji}</span>
              <span className="text-sm font-semibold">
                {initialWords[index].word}
              </span>
            </>
          ) : (
            <span className="text-4xl">?</span>
          )}
        </div>
      ))}
    </div>
  );

  const renderPeekToggle = () => (
    <label className="flex items-center cursor-pointer mt-4">
      <span className="mr-2 text-lg">Peek:</span>
      <input
        type="checkbox"
        checked={isPeekEnabled}
        onChange={() => setIsPeekEnabled(!isPeekEnabled)}
        className="form-checkbox h-5 w-5 text-blue-600"
      />
    </label>
  );

  const renderHardModeToggle = () => (
    <label className="flex items-center cursor-pointer mt-4">
      <span className="mr-2 text-lg">Hard:</span>
      <input
        type="checkbox"
        checked={isHardMode}
        onChange={() => setIsHardMode(!isHardMode)}
        className="form-checkbox h-5 w-5 text-blue-600"
      />
    </label>
  );

  const renderKeyword = () => (
    <div className="flex justify-center mb-4">
      <div className="bg-yellow-100 rounded-lg shadow-md p-2 flex flex-col items-center justify-center h-24 w-24">
        <span className="text-3xl mb-1">{keywordEmoji}</span>
        <span className="text-sm font-semibold">{keyword}</span>
      </div>
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-3xl font-bold text-center mb-8">
        Word Association Game
      </h1>
      {!gameStarted ? (
        <div className="text-center">
          {!isGameGenerated ? (
            <button
              onClick={generateGame}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition transform hover:scale-105 mt-4"
              disabled={isLoading}
            >
              {isLoading ? "Generating..." : "Generate Game"}
            </button>
          ) : (
            <>
              {renderKeyword()}
              {isPeeking ? renderPeekWords() : renderInitialWords()}
              <button
                onClick={startGame}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition transform hover:scale-105 mt-4"
                disabled={isPeeking}
              >
                {isPeeking ? "Peeking..." : "Play"}
              </button>
              <div className="flex justify-center items-center mt-4">
                {/* Comment out or remove the Hard and Peek buttons */}
                {/*
                {renderHardModeToggle()}
                {renderPeekToggle()}
                */}
              </div>
            </>
          )}
        </div>
      ) : !gameOver ? (
        <>
          <div className="bg-gray-100 rounded-lg p-6 mb-8">
            <div className="flex justify-between items-center mb-4">
              <p className="text-lg font-semibold">Round: {currentRound}/5</p>
              <p className="text-lg font-semibold">Score: {score}</p>
            </div>
            {renderKeyword()}
            <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
              <div
                className="bg-blue-600 h-2.5 rounded-full transition-all duration-300 ease-out"
                style={{ width: `${timeLeft}%` }}
              ></div>
            </div>
            {renderWords()}
          </div>
          <div className="bg-gray-100 rounded-lg p-6">
            <h2 className="text-xl font-bold mb-4">Selected Words:</h2>
            {renderSelectedWords()}
          </div>
        </>
      ) : (
        renderGameOver()
      )}
    </div>
  );
};

NumberGridGame.displayName = "NumberGridGame";
