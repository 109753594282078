import React, { memo, useState } from "react";

import "./Dice.scss";
import { useGameplayStore } from "../store/useGameplayStore";
import classNames from "classnames";

type Props = {
  value: number;
  word: string;
  emoji: string;
  locked: boolean;
  dieIndex: number;
  guess: string;
  isPlayerDice: boolean;
  className?: string;
};

export const Dice: React.FC<Props> = memo(
  ({
    value,
    word,
    emoji,
    locked,
    dieIndex,
    guess,
    isPlayerDice,
    className,
  }) => {
    const { lockDie, half } = useGameplayStore();

    // console.error("Myindex", dieIndex);
    const isLocked = locked;
    const isHalfEven = half % 2 === 0;
    // console.log("half: " + "locked: " + isLocked)

    // setState(isLocked ? "locked" : isHalfEven ? "submit" : "");

    // Example usage:

    return (
      <div
        className={classNames("dice", className, {
          locked: isLocked,
          unlocked: !isLocked,
          blue: !isHalfEven && isLocked,
          green: isHalfEven && isLocked,
          [guess]: true,
        })}
        onClick={() => lockDie(dieIndex, isPlayerDice)}
      >
        <div className="die-container">
          {/* <p className="value">{value}</p> */}
          <p className="emoji">{emoji}</p>
          <p className="word">{word}</p>
        </div>
        {/* <br/> */}
        {/* {state === "locked" ? "L" : "U"} */}
        {/* {dieIndex} */}
      </div>
    );
  }
);

Dice.displayName = "Dice";
