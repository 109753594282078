import React, { memo, useState, useEffect } from "react";
import { useWindowDimensions } from "react-native";
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  useSensors,
  useSensor,
  MouseSensor,
  TouchSensor,
  // DragOverlay,
} from "@dnd-kit/core";

import { snapCenterToCursor } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import classNames from "classnames";

import { Draggable } from "./Draggable";
import { Droppable } from "./Droppable";

import { Dice } from "../components/Dice";
import { CombinedCard } from "../components/CombinedCard";
import { useGameplayStore } from "../store/useGameplayStore";
import "./MainGameScreen.scss";

export const MainGameScreen = memo(() => {
  // Extracting state and actions from the gameplay store
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: { distance: 8 },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const {
    reroll,
    playerDies: dies,
    restartGame,
    go,
    flip,
    submitDie,
    rerollCount,
    enemyAttack,
    half,
    playerCategory,
    guessedDice,
    clear,
    dragIn,
    guessRound,
    lockDie,
    swap,
  } = useGameplayStore();

  // Add this useEffect hook near the top of your component
  useEffect(() => {
    restartGame();
  }, []);

  // State to manage enemy health
  const [enemyHealth, setEnemyHealth] = useState(25);
  const renderEnemyAttack = () => (
    <div className="combined-attack-enemy">
      <CombinedCard {...enemyAttack} />
    </div>
  );

  const renderEnemyAttackModal = () =>
    typeof enemyAttack.effect === "object" && (
      <div className="modal combined-attack-enemy">
        <div className="modal-content">
          <CombinedCard {...enemyAttack} />
          <br />
          <button
            className="modal-close"
            onClick={() => {
              useGameplayStore.setState({
                enemyAttack: { ...enemyAttack, effect: "" },
              });
            }}
          >
            Close
          </button>
        </div>
      </div>
    );

  // State to manage the parent container of the draggable item
  const [parent, setParent] = useState<string | null>(null);

  // Arrays representing containers and dice
  const containers = ["A", "B", "C", "D", "E"];
  const dice = ["A", "B", "C", "D", "E"];

  // Draggable markup to be reused
  const draggableMarkup = <Draggable id="draggable">Drag me</Draggable>;
  const droppableMarkup = <Draggable id="droppable">Drop me</Draggable>;

  // Function to handle the end of a drag event
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active && over) {
      const activeId = String(active.id);
      const overId = String(over.id);
      if (activeId.startsWith("guessed-")) {
        const guessedIndex = parseInt(activeId.split("-")[1]);
        swap(guessedIndex, Number(overId));
      } else {
        dragIn(Number(activeId), Number(overId));
      }
    }
    setActiveId(null);
  }

  const [activeId, setActiveId] = useState<string | null>(null);

  function handleDragStart(event: DragStartEvent) {
    setActiveId(String(event.active.id));
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  const renderGameControls = () => (
    <div className="extra-game-controls">
      {/* <button className="clear" onClick={clear}>
        🗑️
      </button> */}
      {half % 2 === 1 && (
        <button
          className="lock"
          onClick={submitDie}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              submitDie();
            }
          }}
        >
          {/* ▶ */}
          {/* Generate Clueword */}
          Play
        </button>
      )}
      {half % 2 === 0 && (
        <button
          className="go"
          onClick={go}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              go();
            }
          }}
        >
          <span className="guessText">
            {/* ▶ */}
            Guess
          </span>
          {/* <span className="roundNum">{guessRound}</span> */}
        </button>
      )}
      {/* <button className="restart" onClick={restartGame}>
        ⟳
      </button> */}
    </div>
  );

  // const { width } = useWindowDimensions();

  return (
    <div
      id="main-game-screen"
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === "space") {
          e.preventDefault();
          if (half % 2 === 0) {
            go();
          } else {
            submitDie();
          }
        }
      }}
      tabIndex={0}
    >
      {renderEnemyAttack()}
      {renderGameControls()}
      <DndContext
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        sensors={sensors}
        // autoScroll={false}
        modifiers={[snapCenterToCursor]}
      >
        <div className="empty-die-container">
          {guessedDice.map((die, index) => (
            <Droppable key={index} id={index.toString()}>
              <Draggable id={`guessed-${index}`}>
                <div className={classNames({ guess: true })}>
                  <Dice className="guess" {...die} isPlayerDice={false} />
                </div>
              </Draggable>
            </Droppable>
          ))}
        </div>
        <div className="player-die-container">
          {dies.map((die, index) => (
            <Draggable key={index} id={index.toString()}>
              <div>
                <Dice key={index} {...die} isPlayerDice={true} />
              </div>
            </Draggable>
          ))}
        </div>
        {/* <DragOverlay dropAnimation={null}>
          {activeId && (
            <div
              className={classNames("draggable-wrapper", {
                guess: activeId.startsWith("guessed-"),
              })}
            >
              <Dice
                {...(activeId.startsWith("guessed-")
                  ? guessedDice[parseInt(activeId.split("-")[1])]
                  : dies[parseInt(activeId)])}
                isPlayerDice={!activeId.startsWith("guessed-")}
                className={classNames({
                  guess: activeId.startsWith("guessed-"),
                  diceHover: true,
                })}
              />
            </div>
          )}
        </DragOverlay> */}
      </DndContext>
    </div>
  );
});

MainGameScreen.displayName = "MainGameScreen";
