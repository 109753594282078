import React, { memo, useState, useEffect } from "react";
import "./CombinedCard.scss";
import { CardInfo } from "../store/CombinedCard";
import { useGameplayStore } from "../store/useGameplayStore";

export type Props = CardInfo;

export const CombinedCard = memo(
  ({ value, emoji, word, body, effect }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(true);
    let { highscore, guessRound, gameResetCounter, half } = useGameplayStore();

    useEffect(() => {
      setIsModalOpen(true);
    }, [gameResetCounter]);

    const scoredWords: any[] = [];
    const missedWords: any[] = [];
    let totalScore = 0;
    let maxScore = 0;
    let isNewHighScore = false;

    if (typeof effect === "object" && effect.Words) {
      effect.Words.Word.forEach((word, index) => {
        const score =
          effect.Words.Value[index] * effect.Words.DifficultyValue[index];
        maxScore += score;

        const wordObj = {
          emoji: effect.Words.Emoji[index],
          word: word,
          reasoning: effect.Words.Reasoning[index],
          score: `${effect.Words.Value[index]} x ${effect.Words.DifficultyValue[index]}`,
          earned: effect.Words.Earned[index],
        };

        if (effect.Words.Earned[index]) {
          scoredWords.push(wordObj);
          totalScore += score;
        } else {
          missedWords.push(wordObj);
        }
      });
    }

    if (totalScore > highscore) {
      useGameplayStore.setState({ highscore: totalScore });
      isNewHighScore = true;
    } else {
      isNewHighScore = false;
    }

    const percentage = Math.round((totalScore / maxScore) * 100);

    const handleCloseModal = () => {
      setIsModalOpen(false);
    };

    // TODO;
    // remove the modal; add logic to update the card element by
    // what is locked (maybe resue the logic for using the object)

    // TODO
    // Add a body to the combinedCard
    return (
      <div className="table">
        <div className="title">
          <span className="emoji">{emoji}</span>
          <h2>{word}</h2>
          {/* {body && <h3>{body}</h3>} */}
          <h3>{body}</h3>
        </div>
        {half % 2 === 0 && (
          <span className="roundsLeft">
            {Array(5 - guessRound)
              .fill(null)
              .map((_, index) => (
                <span key={index} className="heart">
                  ♥
                </span>
              ))}
          </span>
        )}

        {typeof effect === "object" && isModalOpen && (
          <div>
            <div className="title">
              <div className="scored">
                {/* {scoredWords.map((word, index) => (
                <div className="word-row" key={index}>
                  <span className="emoji">{word.emoji}</span>
                  <span className="word">{word.word}</span>
                  <span className="reasoning">{word.reasoning}</span>
                </div>
              ))} */}
              </div>
            </div>
            {/* <div className="modal">
            <div className="modal-content">
              <span className="emoji">{emoji}</span>
              <h2>{word}</h2>
              <div className="scored">
                {scoredWords.map((word, index) => (
                  <div className="word-row" key={index}>
                    <span className="emoji">{word.emoji}</span>
                    <span className="word">{word.word}</span>
                    <span className="reasoning">{word.reasoning}</span>
                  </div>
                ))}
              </div>
              <div className="missed">
                {missedWords.map((word, index) => (
                  <div className="word-row" key={index}>
                    <span className="emoji">{word.emoji}</span>
                    <span className="word">{word.word}</span>
                    <span className="reasoning">{word.reasoning}</span>
                  </div>
                ))}
              </div>
              <div className="highscore">
                <span>
                  <strong>Rounds</strong>
                </span>
                <br />
                <span className="score">{guessRound}</span>
              </div>
              <button className="close-modal" onClick={handleCloseModal}>
                Close
              </button>
            </div>
          </div>
          <div className="overlay"></div> */}
          </div>
        )}
      </div>
    );
  }
);

CombinedCard.displayName = "CombinedCard";
