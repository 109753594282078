import React, { ReactNode } from "react";
import { useDroppable } from "@dnd-kit/core";
import { useGameplayStore } from "../store/useGameplayStore";
import "./Droppable.scss";

interface DroppableProps {
  children: ReactNode;
  id: string;
}

export function Droppable({ children, id }: DroppableProps) {
  const { lockDie } = useGameplayStore();
  const { isOver, setNodeRef } = useDroppable({
    id: id,
  });

  // Check if the child element has a "correct" attribute
  const childElement = React.Children.only(children) as React.ReactElement;
  if (childElement.props.correct) {
    return childElement;
  }

  const style = {
    backgroundColor: isOver ? "black" : undefined,
  };

  return (
    <div
      ref={setNodeRef}
      className={`droppable ${isOver ? "droppable--is-over" : ""}`}
      style={style}
    >
      <div className="droppable__outline"></div>
      {children}
    </div>
  );
}
